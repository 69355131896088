
/* Override browser defaults */
html, body {
  margin: 0;
  padding: 0;
  background-color: #1a1f16;
  font-family: 'Figtree', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 24px;
}

/* Use viewport-relative units to cover page fully */
body {
  height: 100vh;
  width: 100vw;
}


/* Include border and padding in element width and height */
* {
  box-sizing: border-box;
}