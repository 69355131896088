/* General Styles */
h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  color: #EF946C;
}

h2 {
  margin: 0;
  font-size: 38px;
  font-weight: 500;
  color: #f8f8f8;
}

p {
  color: #f8f8f8;
}

a:link, a:visited, a:hover, a:active {
  color: #B93160;
  text-decoration: none;
}

nav > a:link, nav > a:visited, nav > a:hover, nav > a:active {
  color:#EF946C;
}

footer {
  font-family:'Figtree', sans-serif;
  font-size: 14px;
  text-align: center;
}

/* Utility styles */
.emphasis {
  color: #B93160;
  font-weight: 700;
}

.scribble {
  position: relative;
}

/* Component specific styles */

/* Overall app component */
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  padding: 20px;
}

/* Header components */
.app__header {
  display: grid;
  grid-template: 1fr / 1fr 2fr 1fr;
  height: 100px;
  margin-bottom: 80px;
}

.app__nav {
  grid-column: -1;
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 20px;
  padding: 0px 20px;
  color: #EF946C;
  font-size: 28px;
  font-weight: 300;
}

.app__logo {
  display: flex;
  color: #EF946C;
  font-weight: 700;
  font-size: 48px;
  line-height: 40px;
}

.app__logo--text {
  top: 85px;
  left: -80px;
}

.app__linkedin {
  top: 113px;
  left: 152px;
  width: 39px;
  height: 39px;
}

.app__mail {
  top: 63px;
  left: 145px;
  width: 39px;
  height: 39px;
}

.app__arrows {
  top: 55px;
  left: 80px;
}

/* Hero components */
.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin: auto;
  min-height: 500px;
  gap: 100px;
}

.hero__banner {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 20px;
  color: #f8f8f8;
  height: 100px;
}

.hero__banner > img {
  width: 84px;
  height: 84px;
}

.hero__banner > span {
  font-family: 'Shadows Into Light', sans-serif;
  font-size: 42pt;
}

.hero__content--middle {
  position: absolute;
 left: 520px;
 top: 120px;
}

.hero__content--bottom {
  position: absolute;
 left: 530px;
 top: 350px;
}